<template>
  <div class="masonry-wrapper">
    <div class="pt--45 pb--45 masonry-tabs">
      <v-tabs v-model="tab" centered flat hide-slider color="primary">
        <v-tab :ripple="false" v-for="item in tabItems" :key="item.id">{{
          item.name
        }}</v-tab>
      </v-tabs>
    </div>

    <v-tabs-items v-model="tab" no-animation>
      <v-tab-item
        v-for="itemTab in tabItems"
        :key="itemTab.id"
        transition="0"
        reverse-transition="0"
      >
        <vue-masonry-wall :items="itemTab.id === 1 ? allElements : itemTab.elements" :options="options">
          <template v-slot:default="{ item }">
            <div class="portfolio_style--3 Item">
              <div class="thumb">
                <!-- <div class="image-wrapper" @click="showImages(itemTab.elements)"> -->
                <div class="image-wrapper" @click="itemTab.id === 1 ? handleImageClick(allElements, item) : handleImageClick(itemTab.elements, item)">
                  <img :src="item.src" alt="personal portfolio"
                />
              </div>
                <div class="port-hover-action">
                  <div class="content">
                    <h3>
                      <router-link class="titles" to="/portfolio-details">{{item.title}}</router-link>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </vue-masonry-wall>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
// import { VueViewer } from "v-viewer"; 
import VueMasonryWall from "vue-masonry-wall";

  export default {
    components: { VueMasonryWall },
    data() {
      return {
        currentToDisplay:[],
        currentImageIndex:1,
        tab: this.getTabId(),
        index: null,
        options: {
          width: 500,
          padding: {
            default: 7.5,
          },
        },
        tabItems: [
          {
            id: 1,
            name: "All",
            elements: []
          },
          {
            id: 2,
            name: "Sports",
            elements : [
            {
              title: "Action Aitlhaj",
              src: require("../../assets/img/categories/sports/action-morocco/Action-Aitlhaj.jpg")
            },
            {
              title: "Action Aitlhaj 1",
              src: require("../../assets/img/categories/sports/action-morocco/Action-Aitlhaj1.jpg")
            },
            {
              title: "Action Anissa",
              src: require("../../assets/img/categories/sports/action-morocco/Action-Anissa.jpg")
            },
            {
              title: "Action Ayane",
              src: require("../../assets/img/categories/sports/action-morocco/Action-Ayane.jpg")
            },
            {
              title: "Action Ayanne",
              src: require("../../assets/img/categories/sports/action-morocco/Action-Ayanne.jpg")
            },
            {
              title: "Action Elodi Naqqach",
              src: require("../../assets/img/categories/sports/action-morocco/Action-Elodi-Naqqach.jpg")
            },
            {
              title: "Action Jraidi",
              src: require("../../assets/img/categories/sports/action-morocco/Action-Jraidi.jpg")
            },
            {
              title: "Action Jraidi 1",
              src: require("../../assets/img/categories/sports/action-morocco/Action-Jraidi1.jpg")
            },
            {
              title: "Action Saoud",
              src: require("../../assets/img/categories/sports/action-morocco/Action-Saoud.jpg")
            },
            {
              title: "Action Saoud 2",
              src: require("../../assets/img/categories/sports/action-morocco/Action-Saoud2.jpg")
            },
            {
              title: "Action Saoud 3",
              src: require("../../assets/img/categories/sports/action-morocco/Action-Saoud3.jpg")
            },
            {
              title: "Action Saoud 3",
              src: require("../../assets/img/categories/sports/action-morocco/Action-Saoud-3.jpg")
            },
            {
              title: "Action Whole Team",
              src: require("../../assets/img/categories/sports/action-morocco/Action-whole-team.jpg")
            },
            {
              title: "Celebrating a Goal",
              src: require("../../assets/img/categories/sports/action-morocco/Celebrating-a-goal.jpg")
            },
            {
              title: "Celebrating a Goal Jraidi",
              src: require("../../assets/img/categories/sports/action-morocco/Celebrating-a-goal-Jraidi.jpg")
            },
            {
              title: "Elodie Naqach Shooting",
              src: require("../../assets/img/categories/sports/action-morocco/Elodie-Naqach-shooting.jpg")
            },
            {
              title: "FIFA Ref",
              src: require("../../assets/img/categories/sports/action-morocco/Fifa-Ref.jpg")
            },
            {
              title: "Group Photo National Team 1",
              src: require("../../assets/img/categories/sports/action-morocco/Group-Photo-National-team-1.jpg")
            },
            {
              title: "Group Photo with the Captain",
              src: require("../../assets/img/categories/sports/action-morocco/Group-photo-with-the-captain.jpg")
            },
            {
              title: "Lmontakhab 5",
              src: require("../../assets/img/categories/sports/action-morocco/lmontakhab5.jpg")
            },
            {
              title: "Morocco Group Photo",
              src: require("../../assets/img/categories/sports/action-morocco/Morocco-Group-Photo.jpg")
            },
            {
              title: "Namibia's Group Photo",
              src: require("../../assets/img/categories/sports/action-morocco/Namibia's-group-photo.jpg")
            },
            {
              title: "National Team Sakina Ouzraoui",
              src: require("../../assets/img/categories/sports/action-morocco/National-Team-Sakina-Ouzraoui.jpg")
            },
            {
              title: "National Team Sakina Ouzraoui 2",
              src: require("../../assets/img/categories/sports/action-morocco/National-Team-Sakina-Ouzraoui2.jpg")
            },
            {
              title: "Rosella Ayane Team Photos",
              src: require("../../assets/img/categories/sports/action-morocco/Rosella-Ayane-Team-Photos.jpg")
            },
            {
              title: "Rosella Elodi Saoud and Anissa",
              src: require("../../assets/img/categories/sports/action-morocco/Rosella-Elodi-Saoud-and-Anissa.jpg")
            },
              {
                title: "Whole Team Running",
                src: require("../../assets/img/categories/sports/action-morocco/whole-team-running.jpg")
              },
              {
                title: "Namibia Coach",
                src: require("../../assets/img/categories/sports/action-namibia/Namibia-Coach.jpg")
              },
              {
                title: "Namibia Group Photos",
                src: require("../../assets/img/categories/sports/action-namibia/Namibia-Group-Photos.jpg")
              },
              {
                title: "Namibia Group Photos 1",
                src: require("../../assets/img/categories/sports/action-namibia/Namibia-Group-Photos-1.jpg")
              },
              {
                title: "Namibia Group Photos 3",
                src: require("../../assets/img/categories/sports/action-namibia/Namibia-Group-Photos-3.jpg")
              },
              {
                title: "Namibia Player",
                src: require("../../assets/img/categories/sports/action-namibia/Namibia-Player.jpg")
              },
              {
                title: "Namibia's Group Photo",
                src: require("../../assets/img/categories/sports/action-namibia/Namibia's-group-photo.jpg")
              },
              {
                title: "Anissa Lahmari and Ibtissam Jraidi National Team",
                src: require("../../assets/img/categories/sports/training-morocco/Anissa-Lahmari-and-Ibtissam-Jraidi-National-Team.jpg")
              },
              {
                title: "Anissa Lahmari and Ibtissam Jraidi National Team 2",
                src: require("../../assets/img/categories/sports/training-morocco/Anissa-Lahmari-and-Ibtissam-Jraidi-National-Team2.jpg")
              },
              {
                title: "Lmontakhab 1",
                src: require("../../assets/img/categories/sports/training-morocco/lmontakhab-1.jpg")
              },
              {
                title: "Lmontakhab 2",
                src: require("../../assets/img/categories/sports/training-morocco/lmontakhab2.jpg")
              },
              {
                title: "Lmontakhab 3",
                src: require("../../assets/img/categories/sports/training-morocco/lmontakhab3.jpg")
              },
              {
                title: "Lmontakhab 4",
                src: require("../../assets/img/categories/sports/training-morocco/lmontakhab-4.jpg")
              },
              {
                title: "Lmontakhab 6",
                src: require("../../assets/img/categories/sports/training-morocco/lmontakhab6.jpg")
              },
              {
                title: "Lmontakhab 6 Corrected",
                src: require("../../assets/img/categories/sports/training-morocco/Lmontakhab6corrected.jpg")
              },
              {
                title: "Lmontakhab 7",
                src: require("../../assets/img/categories/sports/training-morocco/lmontakhab7.jpg")
              },
              {
                title: "Lmontakhab 8",
                src: require("../../assets/img/categories/sports/training-morocco/lmontakhab8.jpg")
              },
              {
                title: "Lmontakhab 9",
                src: require("../../assets/img/categories/sports/training-morocco/lmontakhab9.jpg")
              },
              {
                title: "Lmontakhab 10",
                src: require("../../assets/img/categories/sports/training-morocco/lmontakhab10.jpg")
              },
              {
                title: "Lmontakhab 11",
                src: require("../../assets/img/categories/sports/training-morocco/lmontakhab11.jpg")
              },
              {
                title: "Lmontakhab 12",
                src: require("../../assets/img/categories/sports/training-morocco/lmontakhab12.jpg")
              },
              {
                title: "Lmontakhab 14 Corrected",
                src: require("../../assets/img/categories/sports/training-morocco/lmontakhab14corrected.jpg")
              },
              {
                title: "Lmontakhab 15",
                src: require("../../assets/img/categories/sports/training-morocco/lmontakhab15.jpg")
              },
              {
                title: "Lmontakhab 16",
                src: require("../../assets/img/categories/sports/training-morocco/lmontakhab16.jpg")
              },
              {
                title: "Lmontakhab 17",
                src: require("../../assets/img/categories/sports/training-morocco/lmontakhab-17.jpg")
              },
              {
                title: "Lmontakhab 18",
                src: require("../../assets/img/categories/sports/training-morocco/lmontakhab18.jpg")
              },
              {
                title: "Lmontakhab Coach",
                src: require("../../assets/img/categories/sports/training-morocco/lmontakhab-coach.jpg")
              },
              {
                title: "Lmontakhab Coach 2",
                src: require("../../assets/img/categories/sports/training-morocco/lmontakhab-coach2.jpg")
              },
              {
                title: "National Team Sarah Kassi",
                src: require("../../assets/img/categories/sports/training-morocco/National Team Sarah Kassi.jpg")
              },
              {
                title: "National Team Ines Arouaissa GK",
                src: require("../../assets/img/categories/sports/training-morocco/National-Team-Ines-Arouaissa-GK.jpg")
              },
              {
                title: "National Team Ines Kbida",
                src: require("../../assets/img/categories/sports/training-morocco/National-Team-Ines-Kbida.jpg")
              },
              {
                title: "National Team Ines Kbida 2",
                src: require("../../assets/img/categories/sports/training-morocco/National-Team-Ines-Kbida2.jpg")
              },
              {
                title: "National Team Kenza Chapelle",
                src: require("../../assets/img/categories/sports/training-morocco/National-Team-Kenza-Chapelle.jpg")
              },
              {
                title: "National Team Samya Hassani",
                src: require("../../assets/img/categories/sports/training-morocco/National-Team-Samya-Hassani-.jpg")
              },
              {
                title: "National Team Yasmine Zouhir 2",
                src: require("../../assets/img/categories/sports/training-morocco/National-Team-Yasmine-Zouhir-2.jpg")
              },
              {
                title: "National Team Yassmine Zouhir",
                src: require("../../assets/img/categories/sports/training-morocco/National-Team-Yassmine-Zouhir.jpg")
              },
              {
                title: "Rosella Ayane National Team",
                src: require("../../assets/img/categories/sports/training-morocco/Rosella-Ayane-National-Team.jpg")
              },
              {
                title: "Yassmine Amrabat",
                src: require("../../assets/img/categories/sports/training-morocco/Yassmine-Amrabat.jpg")
              },
              ]
          },
          {
            id: 3,
            name: "Products",
            elements: [
              {
                title: "Example of Title 1",
                src: require("../../assets/img/categories/products/first/Adidas-football-shoes-with-logo.jpg")
              },
              {
                title: "Example of Title 2",
                src: require("../../assets/img/categories/products/first/Adidas-shoe.jpg")
              },
              {
                title: "Example of Title 3",
                src: require("../../assets/img/categories/products/first/adidas-shoe-with-logo.jpg")
              },
              {
                title: "Example of Title 4",
                src: require("../../assets/img/categories/products/first/Crazyfast-Messi.3.jpg")
              },
              {
                title: "Example of Title 5",
                src: require("../../assets/img/categories/products/first/Football-adidas-shoes-without-logo.jpg")
              }
            ]
          },
          {
            id: 4,
            name: "Fashion",
            elements: [
            {
                title: "Fashion",
                src: require("../../assets/img/categories/fashion/1.jpg")
              },
              {
                title: "Fashion",
                src: require("../../assets/img/categories/fashion/2.jpg")
              },
              {
                title: "Fashion",
                src: require("../../assets/img/categories/fashion/3.jpg")
              },
              {
                title: "Fashion",
                src: require("../../assets/img/categories/fashion/4.jpg")
              },
              {
                title: "Fashion",
                src: require("../../assets/img/categories/fashion/5.jpg")
              },
              {
                title: "Fashion",
                src: require("../../assets/img/categories/fashion/6.jpg")
              },
              {
                title: "Fashion",
                src: require("../../assets/img/categories/fashion/7.jpg")
              },
              {
                title: "Fashion",
                src: require("../../assets/img/categories/fashion/8.jpg")
              },
              {
                title: "Fashion",
                src: require("../../assets/img/categories/fashion/9.jpg")
              },
              {
                title: "Fashion",
                src: require("../../assets/img/categories/fashion/10.jpg")
              },
              {
                title: "Fashion",
                src: require("../../assets/img/categories/fashion/11.jpg")
              },
              {
                title: "Fashion",
                src: require("../../assets/img/categories/fashion/12.jpg")
              },
              {
                title: "Fashion",
                src: require("../../assets/img/categories/fashion/13.jpg")
              },
            ]
          },
          
          {
            id: 5,
            name: "Sales",
            elements: [

              {
                title: "Prints",
                src: require("../../assets/img/categories/Prints-for-sale-Available-SOON/1.jpg")
              },
              {
                title: "Prints",
                src: require("../../assets/img/categories/Prints-for-sale-Available-SOON/2.jpg")
              },
              {
                title: "Prints",
                src: require("../../assets/img/categories/Prints-for-sale-Available-SOON/3.jpg")
              },
              {
                title: "Prints",
                src: require("../../assets/img/categories/Prints-for-sale-Available-SOON/4.jpg")
              },
              {
                title: "Prints",
                src: require("../../assets/img/categories/Prints-for-sale-Available-SOON/5.jpg")
              },
              {
                title: "Prints",
                src: require("../../assets/img/categories/Prints-for-sale-Available-SOON/6.jpg")
              },
              {
                title: "Prints",
                src: require("../../assets/img/categories/Prints-for-sale-Available-SOON/7.jpg")
              },
              {
                title: "Prints",
                src: require("../../assets/img/categories/Prints-for-sale-Available-SOON/8.jpg")
              },
              {
                title: "Prints",
                src: require("../../assets/img/categories/Prints-for-sale-Available-SOON/9.jpg")
              },
              {
                title: "Prints",
                src: require("../../assets/img/categories/Prints-for-sale-Available-SOON/10.jpg")
              },
              {
                title: "Prints",
                src: require("../../assets/img/categories/Prints-for-sale-Available-SOON/11.jpg")
              },
            ]

          },
        ],
        tabContent: [],
      };
    },
    methods: {
      handleImageClick(items, current) {
        this.currentImageIndex = items.indexOf(current);
        this.currentToDisplay = items;
        this.showImages();
    },
      buildContenArray(index, count) {
        var array = [];
        for(var i=0; i<count; i++) {
          var obj = {
            src: '',
            // src: require(folders[index] + (i+1) + '.jpg'),
            title: 'test'
          }
          array.push(obj);
        }
        return array;
      },
      showImages() {
        console.log('element to show: ' + this.currentImageIndex);
        this.$viewerApi({
          images: this.currentToDisplay,
          options: {
            initialViewIndex: this.currentImageIndex
          },
        });
      },
      getTabId() {
        var savedElem = localStorage.getItem('currentPortfolio');
        if(savedElem) {
          localStorage.removeItem('currentPortfolio')
          return parseInt(savedElem);
        } else {
          return 1;
        }
      }
    },
    created() {
      // this.tabContent = this.tabItems.map((item, index) => ({
      this.tabContent = this.tabItems.map((item) => ({
      id: item.id,
      // content: this.buildContenArray(index, 10),
      content: [],
    }));
    console.log(this.tabContent);
  },
  computed: {
  // Compute the elements for the "All" category
  allElements() {
    return this.tabItems
      .filter((item) => item.id !== 1)
      .flatMap((item) => item.elements);
  }
}
  };
</script>

<style scoped lang="scss">
  .Item {
    overflow: hidden;
    width: 100%;
    background: #f5f5f5;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    line-height: 0;
    display: block;
  }
  .portfolio_style--3 {
    margin-bottom: 0;
  }
  .masonry-wall {
    flex-wrap: wrap;
  }
  .image-wrapper {
    transition: all 0.4s ease-in-out 0s;
  }
  .titles {
    color: white;
    font-size: 700;
  }
  .masonry-tabs .v-slide-group__wrapper .v-slide-group__content {
    justify-content: center !important;
  }
</style>
